import { BuyerStates } from '@/legacy/buyers/buyerSummary.model';
import { ReferrerStates } from '@/legacy/referrers/referrerSummary.model';
import { SellerStates } from '@/legacy/sellers/sellerSummary.model';
import { UserSummary } from '@/state/types';

export const isActiveBuyer = (userSummary: UserSummary | null) =>
  userSummary?.buyer?.state === BuyerStates.ACTIVATED;

export const isActiveSeller = (userSummary: UserSummary | null) =>
  userSummary?.seller?.state === SellerStates.ACTIVATED;

export const isActiveReferrer = (userSummary: UserSummary | null) =>
  userSummary?.referrer?.state === ReferrerStates.ACTIVATED;
